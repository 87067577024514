<template>
  <div>
    <div class="container">
      <p class="title">请注册管理员账号</p>
      <div class="submit">
        <van-field
                   v-model="name"
                   left-icon="user-circle-o"
                   placeholder="请输入用户名" />
        <van-field
                   v-model="phone"
                   left-icon="user-circle-o"
                   placeholder="请输入手机号" />

        <van-field
                   type="password"
                   v-model="passwd"
                   left-icon="closed-eye"
                   placeholder="请输入密码" />
      </div>
      <div class="forget">
        <p>已经有账号了？<span @click="goLogin">登录</span></p>
        <!-- <p>忘记密码</p> -->
      </div>
      <van-button @click="subFn" class="sub" block round type="info" color="#ec6362">注册</van-button>
    </div>
  </div>
</template>

<script>
import { register } from '../api/index.js';
import { Notify } from 'vant';

export default {
 data() {
  return {
   name: '',
   phone: '',
   passwd: '',
   phoneNumberTest: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
  };
 },
 methods: {
  goLogin() {
   this.$router.push({ path: '/login' });
  },
  subFn() {
   this.$toast.loading({
    message: '加载中...',
    forbidClick: true
   });

   if (!this.phoneNumberTest.test(this.phone)) {
    this.$toast({ message: '请输入正确手机号', duration: 1500 });
    return;
   }
   register({
    name: this.name,
    phone: this.phone,
    passwd: this.passwd,
    role: 0
   }).then((res) => {
    if (res.data == 1) {
     Notify({ type: 'success', message: '注册成功！' });
     this.$toast.clear();
     this.$router.push({ path: '/login' });
    } else {
     Notify({ type: 'danger', message: '该用户已注册' });
    }
   });
  }
 }
};
</script>

<style lang="scss" scoped>
.container {
 max-width: 375px;
 margin: 0 auto;
 background-color: #f4f4f4;
 height: 100vh;
 padding: 30px 30px 0;
}
.sub {
 margin-top: 80px;
 text-align: center;
 line-height: 40px;
 border-radius: 20px;
 font-size: 18px;
 height: 40px;
 color: #fff;
 background-color: #ec6362;
}
.forget {
 margin-top: 20px;
 padding: 0 5px;
 display: flex;
 font-size: 14px;
 justify-content: space-between;
}
.forget span {
 color: #398a86;
}
.submit {
 display: flex;
 flex-direction: column;
 margin-top: 30px;
}
.van-cell {
 background-color: rgba(255, 255, 255, 0.925);
 border-radius: 8px;
 margin-top: 20px;
}
.title {
 margin-top: 50px;
 font-size: 22px;
 font-weight: 500;
}
</style>