import Vue from 'vue';
import axios from 'axios';
import configs from '@/utils/config';
// import Routers from '../routers';
import { Toast } from 'vant';
// 请根据项目进行配置
// 创建axios实例
const service = axios.create({
	baseURL: configs.Url, // api的base_url
	timeout: 120000 // 30s请求超时时间
	// withCredentials: true // 表示跨域请求时是否需要使用凭证
});

// request拦截器
service.interceptors.request.use(
	//   当前台界面使用GET或POST方式提交数据时，数据编码格式由请求头的ContentType指定。分为以下几种情况：
	// 1. application/x-www-form-urlencoded，这种情况的数据@RequestParam、@ModelAttribute可以处理，@RequestBody也可以处理。
	// 2. multipart/form-data，@RequestBody不能处理这种格式的数据。（form表单里面有文件上传时，必须要指定enctype属性值为multipart/form-data，意思是以二进制流的形式传输文件。）
	// 3. application/json、application/xml等格式的数据，必须使用@RequestBody来处理。

	(config) => {
		Toast.loading({
			message: '加载中...',
			forbidClick: true
		});
		// application/x-www-form-urlencoded
		// config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
		// config.headers['content-type'] = 'application/json';
		// config.headers['content-type'] = 'multipart/form-data';
		// Content-Type:application/octet-stream
		config.headers['content-type'] = 'application/json';
		return config;
	},
	(error) => {
		// Vue.$loading.hide()

		// Vue.prototype.$cube.alert('请求失败，请稍后重试！');
		Promise.reject(error);
	}
);
// respone拦截器
service.interceptors.response.use(
	(response) => {
		/**
		 * code为非200是抛错
		 */

		if (response.status === 200) {
			Toast.clear();
			// const res = response.data;
			// if (response.data.code == 500) {
			// 	Vue.prototype.$cube.alert(response.data.msg);
			// } else if (response.data.code == 401) {
			// 	localStorage.removeItem('token');
			// 	Vue.prototype.$cube.alert(response.data.msg);
			// }

			return response.data;
		} else {
			// Vue.$loading.hide();
			// Vue.prototype.$cube.alert('请求失败，请稍后重试！');
			return Promise.reject('error');
		}
	},
	(error) => {
		// Vue.$loading.hide();
		// Vue.prototype.$cube.alert('请求失败，请稍后重试！');
		return Promise.reject(error);
	}
);
export default service;
