<template>
  <div class="container">
    <div class="title">修改项目</div>
    <div class="main">
      <van-field v-model="itemDetail.companyName" @click="showCompany=true" disabled label="公司名称："
                 placeholder="请输入公司名称" />
      <van-field v-model="itemDetail.proType" placeholder="类型" label="类型：" />
      <van-field v-model="itemDetail.proUser" placeholder="请输入负责人" label="负责人：" />
      <van-field v-model="itemDetail.proPhone" placeholder="请输入负责人联系方式" label="负责人电话：" />
      <van-field v-model="itemDetail.projectManager" placeholder="请输入项目经理名称" label="项目经理：" />

      <van-field v-model="itemDetail.proFirst" placeholder="请输入甲方名称" label="甲方名称：" />
      <van-field v-model="itemDetail.invoicingInfo" placeholder="请输入开票信息" label="开票信息：" />
      <van-field v-model="itemDetail.proName" placeholder="请输入工程名称" label="工程名称：" />
      <van-field v-model="itemDetail.proAddr" placeholder="请输入工程地址" label="工程地址：" />
      <van-field v-model="itemDetail.proAmt" placeholder="请输入合同金额" label="合同金额：" />

      <van-field v-model="itemDetail.proTime" @click="showEnd=true" label="开工日期：" readonly placeholder="请选择提交日期" />
      <van-field v-model="itemDetail.completeTime" @click="showCompleteTime=true" readonly label="竣工日期：" placeholder="请选择竣工日期" />

      <van-popup v-model="showEnd" position="bottom" :style="{ height: '50%' }">
        <van-datetime-picker
                             swipe-duration=1000
                             @cancel='showEnd=false'
                             @confirm='choiseEndTime'
                             :value='currentDate'
                             type="date"
                             title="选择结束日期"
                             :min-date="minDate"
                             :max-date="maxDate" />
      </van-popup>

      <van-field
                 v-model="itemDetail.proConment"
                 rows="2"
                 autosize
                 label="详情"
                 type="textarea"
                 maxlength="50"
                 placeholder="请输入留言"
                 show-word-limit />
      <div class="img-title">合同资料：</div>
      <van-uploader name="contract" :preview-options="option" v-model="contractShow" :before-delete='delImg' :after-read="imgUpload" />
      <div class="img-title">中标通知书资料：</div>
      <van-uploader name="successful" :preview-options="option" v-model="successfulShow" :before-delete='delImg' :after-read="imgUpload" />
      <div class="img-title">验收单资料：</div>
      <van-uploader name="acceptance" :preview-options="option" v-model="acceptanceShow" :before-delete='delImg' :after-read="imgUpload" />
      <div class="img-title">收款单资料：</div>
      <van-uploader name="receipts" :preview-options="option" v-model="receiptsShow" :before-delete='delImg' :after-read="imgUpload" />
      <div class="img-title">竣工资料：</div>
      <van-uploader name="information" :preview-options="option" v-model="informationShow" :before-delete='delImg' :after-read="imgUpload" />
      <div class="img-title">发票资料：</div>
      <van-uploader name="invoice" :preview-options="option" v-model="invoiceShow" :before-delete='delImg' :after-read="imgUpload" />
      <div class="img-title">公示资料：</div>
      <van-uploader name="screenshot" :preview-options="option" v-model="screenshotShow" :before-delete='delImg' :after-read="imgUpload" />
      <div class="img-title">预算资料：</div>
      <van-uploader name="budget" :preview-options="option" v-model="budgetShow" :before-delete='delImg' :after-read="imgUpload" />
      <div class="img-title">其他资料：</div>
      <van-uploader name="other" :preview-options="option" v-model="otherShow" :before-delete='delImg' :after-read="imgUpload" />

    </div>
    <div class="btn-container">
      <button class="btn" @click="submit">提交修改</button>
    </div>
    <van-popup v-model="showCompany" position="bottom" :style="{ height: '50%' }">

      <van-picker
                  v-model="showCompany"
                  title="选择公司"
                  show-toolbar
                  :columns="columns"
                  @confirm="selectCompany" />
    </van-popup>
     <van-popup v-model="showCompleteTime" position="bottom" :style="{ height: '50%' }">
        <van-datetime-picker
                             swipe-duration=1000
                             @cancel='showCompleteTime=false'
                             @confirm='choiseCompleteTime'
                             :value='currentDate'
                             type="date"
                             title="选择结束日期"
                             :min-date="minDate"
                             :max-date="maxDate" />
      </van-popup>
    <van-tabbar route>
      <van-tabbar-item to="/home" icon="wap-home-o">查询</van-tabbar-item>
      <van-tabbar-item to="/add" icon="search">添加</van-tabbar-item>
      <van-tabbar-item to='/user' icon="friends-o">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { updateManager, fileUpload } from '../api/index.js';
import Compressor from 'compressorjs';
import moment from 'moment';
import { Notify,Toast } from 'vant';

export default {
 name: 'modify',
 data() {
  return {
    option:{closeable: true},
    showCompleteTime:false,
   showCompany: false,
   columns: ['北京一力建设', '中际三立', '北京华施建设', '爱巢家', '辉跃建设'],
   itemDetail: {},
   contract: [], //合同
   contractShow: [], //合同
   successful: [], //中标通知书
   successfulShow: [], //中标通知书
   acceptance: [], //验收单
   acceptanceShow: [], //验收单
   receipts: [], //收款单
   receiptsShow: [], //收款单
   information: [], //竣工资料
   informationShow: [], //竣工资料
   invoice: [], //发票资料
   invoiceShow: [], //发票资料
   screenshot: [], //公示资料
   screenshotShow: [], //公示资料
   budget: [], //预算资料
   budgetShow: [], //预算资料
   other: [], //其他
   otherShow: [], //其他
   showEnd: false,
   currentDate: '',
   minDate: new Date(2015, 0, 1),
   maxDate: new Date(2035, 1, 1)
  };
 },
 activated() {
  console.log('修改页面');
  this.itemDetail={}; 
  this.contract= [], //合同
   this.contractShow=[], //合同
   this.successful=[], //中标通知书
   this.successfulShow=[], //中标通知书
   this.acceptance=[], //验收单
   this.acceptanceShow=[], //验收单
   this.receipts=[], //收款单
   this.receiptsShow=[], //收款单
   this.information=[], //竣工资料
   this.informationShow=[], //竣工资料
   this.invoice=[], //发票资料
   this.invoiceShow=[], //发票资料
   this.screenshot=[], //公示资料
   this.screenshotShow=[], //公示资料
   this.budget=[], //预算资料
   this.budgetShow=[], //预算资料
   this.other=[], //其他
   this.otherShow=[], //其他
  this.itemDetail = JSON.parse(localStorage.getItem('itemDetail'));
  var imgList = this.itemDetail.pngAll[0];

  for (const key in imgList) {
   if (key == 'contract') {
    imgList[key].split(',').forEach((element) => {
     console.log('element1', element);
     if (element) {
      this.contractShow.push({ url: element });
      this.contract.push(element);
     }
    });
   }
   if (key == 'successful') {
    imgList[key].split(',').forEach((element) => {
     if (element) {
      this.successfulShow.push({ url: element });
      this.successful.push(element);
     }
    });
   }
   if (key == 'acceptance') {
    imgList[key].split(',').forEach((element) => {
     if (element) {
      this.acceptanceShow.push({ url: element });
      this.acceptance.push(element);
     }
    });
   }
   if (key == 'receipts') {
    imgList[key].split(',').forEach((element) => {
     if (element) {
      this.receiptsShow.push({ url: element });
      this.receipts.push(element);
     }
    });
   }
   if (key == 'information') {
    imgList[key].split(',').forEach((element) => {
     if (element) {
      this.informationShow.push({ url: element });
      this.information.push(element);
     }
    });
   }
   if (key == 'invoice') {
    imgList[key].split(',').forEach((element) => {
     if (element) {
      this.invoiceShow.push({ url: element });
      this.invoice.push(element);
     }
    });
   }
   if (key == 'screenshot') {
    imgList[key].split(',').forEach((element) => {
     if (element) {
      this.screenshotShow.push({ url: element });
      this.screenshot.push(element);
     }
    });
   }
   if (key == 'budget') {
    imgList[key].split(',').forEach((element) => {
     if (element) {
      this.budgetShow.push({ url: element });
      this.budget.push(element);
     }
    });
   }
   if (key == 'other') {
    imgList[key].split(',').forEach((element) => {
     if (element) {
      this.otherShow.push({ url: element });
      this.other.push(element);
     }
    });
   }
  }
 },

 methods: {
  close(url) {
   console.log('关闭', url);
   this.donw(url);
  },
  // 选择公司
  selectCompany(value) {
   this.itemDetail.companyName = value;
   this.showCompany = false;
  },
  delImg(val, item) {
             
   if (item.name == 'contract') {
    this.contractShow.splice(item.index, 1);
    this.contract.splice(item.index, 1);   
   } else if (item.name == 'successful') {
    this.successfulShow.splice(item.index,  1);
    this.successful.splice(item.index,  1);
   } else if (item.name == 'acceptance') {
    this.acceptanceShow.splice(item.index, 1);
    this.acceptance.splice(item.index,1);
    console.log('this.acceptanceShow',this.acceptanceShow);
    console.log('this.acceptance',this.acceptance);
   } else if (item.name == 'receipts') {
    this.receiptsShow.splice(item.index,  1);
    this.receipts.splice(item.index,  1);
   } else if (item.name == 'information') {
    this.informationShow.splice(item.index,  1);
    this.information.splice(item.index,  1);
   } else if (item.name == 'invoice') {
    this.invoiceShow.splice(item.index,  1);
    this.invoice.splice(item.index,  1);
   } else if (item.name == 'screenshot') {
    this.screenshotShow.splice(item.index,  1);
    this.screenshot.splice(item.index,  1);
   } else if (item.name == 'budget') {
    this.budgetShow.splice(item.index,  1);
    this.budget.splice(item.index,  1);
   } else if (item.name == 'other') {
    this.otherShow.splice(item.index,  1);
    this.other.splice(item.index,  1);
   }    
  },
  imgUpload(file, item) {
   console.log('imgUpload', item);
   file.status = 'uploading';
   file.message = '上传中';
   var files = new FormData();
   files.append('file', file.file);
   fileUpload(files).then((res) => {
    console.log('upres', res);
    file.status = 'done';
    file.message = '上传完毕';
    if (item.name == 'contract') {
     this.contract.push(res.data);
    } else if (item.name == 'successful') {
     this.successful.push(res.data);
    } else if (item.name == 'acceptance') {
     this.acceptance.push(res.data);
    } else if (item.name == 'receipts') {
     this.receipts.push(res.data);
    } else if (item.name == 'information') {
     this.information.push(res.data);
    } else if (item.name == 'invoice') {
     this.invoice.push(res.data);
    } else if (item.name == 'screenshot') {
     this.screenshot.push(res.data);
    } else if (item.name == 'budget') {
     this.budget.push(res.data);
    } else {
     this.other.push(res.data);
    }
   });
  },
  donw(item) {
   fetch(item.url, { method: 'get' }).then((res) =>
    res.blob().then((blob) => {
     var reader = new FileReader();
     reader.readAsDataURL(blob); // 转换为base64，可以直接放入a标签href
     reader.onload = function (e) {
      var down = document.createElement('a'); // 转换完成，创建一个a标签用于下载
      down.download = item.index;
      down.href = String(e.target.result);
      // document.appendChild(down)
      down.click();
      down.remove();
     };
    })
   );
  },
  submit() {
   updateManager({
    companyName: this.itemDetail.companyName, //公司名称
    proName: this.itemDetail.proName, //项目名称
    proUser: this.itemDetail.proUser, //负责人
    proType: this.itemDetail.proType, //项目类型
    projectManager: this.itemDetail.projectManager,//项目经理
    proPhone: this.itemDetail.proPhone, //负责人电话
    proFirst: this.itemDetail.proFirst, //甲方名称
    InvoicingInfo: this.itemDetail.invoicingInfo, //开票信息
    proConment: this.itemDetail.proConment, //项目描述
    proAddr: this.itemDetail.proAddr, //工程地址
    proAmt: this.itemDetail.proAmt, //工程金额
    proTime: this.itemDetail.proTime,
    completeTime: this.itemDetail.completeTime,//竣工时间
    managerid: this.itemDetail.id,
    id: this.itemDetail.id,
    contract: this.contract.join(','), //合同
    successful: this.successful.join(','), //中标通知书
    acceptance: this.acceptance.join(','), //验收单
    receipts: this.receipts.join(','), //收款单
    information: this.information.join(','), //竣工资料
    invoice: this.invoice.join(','), //发票资料
    screenshot: this.screenshot.join(','), //公示资料
    budget: this.budget.join(','), //预算资料
    other: this.other.join(',') //其他资料
   }).then((res) => {
    if (res.code == 200) {
     Notify({ type: 'success', message: '修改成功', duration: 1500 });
     this.$router.push('home');
    }
    console.log('res修改', res);
   });
  },
  choiseEndTime(val) {
   this.showEnd = false;
   this.itemDetail.proTime = moment(val).format('YYYY-MM-DD');
   console.log(' this.itemDetail.proTime', typeof this.itemDetail.proTime);
  },
   choiseCompleteTime(val) {
   this.showCompleteTime = false;
   this.itemDetail.completeTime = moment(val).format('YYYY-MM-DD');
  },
 }
};
</script>

<style lang="scss" scoped>
.container {
 padding: 10px 20px;
 margin: 0 auto;
 min-height: 1000px;
 background-color: #f4f4f4;
}
.title{
  font-size: 22px;
 margin: 10px 0;
}
.btn-container {
 display: flex;
 justify-content: center;
 align-items: center;
 padding: 20px;
 margin-bottom: 50px;
}
.btn {
 width: 100px;
 height: 30px;
 color: #fff;
 background-color: #36d;
 font-size: 20px;
 border-radius: 10px;

 //  margin: 50px;
}
.main {
 background-color: #fff;
 margin: 0 20px;
 max-width: 1000px;
 margin: 0 auto;
}
.title {
 font-size: 22px;
}
.img-title {
 font-size: 16px;
 color: #646566;
 padding: 10px 16px;
}
.van-uploader {
 margin: 10px 16px;
}
</style>