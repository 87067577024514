import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/assets/css/reset.css';
import common from '@/assets/js/common';
Vue.config.productionTip = false;
import { Icon } from 'vant';
import { Field } from 'vant';
import { Button } from 'vant';
import { Tabbar, TabbarItem } from 'vant';
import { DatetimePicker } from 'vant';
import { Popup } from 'vant';
import { Uploader } from 'vant';
import { Pagination } from 'vant';
import { Collapse, CollapseItem } from 'vant';
import { Switch } from 'vant';
import { Toast } from 'vant';
import { Notify } from 'vant';
import { Picker } from 'vant';
// import Vconsole from 'vconsole';

// 所有环境均使用
// new Vconsole();
Vue.use(Picker);
import { loading } from 'vant';
Vue.use(Toast);
Vue.use(loading);
// 全局注册
Vue.use(Notify);

Vue.use(Switch);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Pagination);
Vue.use(Uploader);
Vue.use(Popup);
Vue.use(DatetimePicker);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Button);
Vue.use(Field);
Vue.use(Icon);
new Vue({
	router,
	store,
	render: (h) => h(App)
}).$mount('#app');
