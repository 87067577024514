<template>
    <div class="container">
        
        <div class="top">
            <span class="title">请选择年份</span>
            <div class="choise" @click="goYear">                    
                <van-button color="#59a6c9" @click="goYear('2018-01-01','2018-12-31')"  type="default">2018年</van-button>
                <van-button color="#59a6c9" @click="goYear('2019-01-01','2019-12-31')"  type="default">2019年</van-button>
                <van-button color="#59a6c9" @click="goYear('2020-01-01','2020-12-31')"  type="default">2020年</van-button>
                <van-button color="#59a6c9" @click="goYear('2021-01-01','2021-12-31')"  type="default">2021年</van-button>
                <van-button color="#59a6c9" @click="goYear('2022-01-01','2022-12-31')"  type="default">2022年</van-button>
                <van-button color="#59a6c9" @click="goYear('2023-01-01','2023-12-31')"  type="default">2023年</van-button>
                <van-button color="#59a6c9" @click="goYear('2024-01-01','2024-12-31')"  type="default">2024年</van-button>
                <van-button color="#59a6c9" @click="goYear('2025-01-01','2025-12-31')"  type="default">2025年</van-button>            
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'selectYear',
        created () {
          console.log('comopany::',this.$route.query.company);
        },
        methods: {
            goYear(startYear,endYear){
                 this.$router.push({name:'Home',query:{startYear:startYear,endYear:endYear,company:this.$route.query.company}});

            }
    }
    }
</script>

<style lang="scss" scoped>
.container {
 padding: 0px 20px;
 margin: 0 auto;
 min-height: 800px;
 background-color: #f4f4f4;
 padding-bottom: 70px;
}

.choise{
    background-color: #fff;
    min-height: 500px;
    padding-bottom: 30px;
    border-radius: 10px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    
.van-button--normal {
    width: 250px;
    margin-top: 30px;
    border-radius: 8px;
    font-size: 16px;
}
}
.title {
 font-size: 24px;
color: #000000c1;
}
</style>