import request from '@/utils/request';

//注册用户
export function register(data) {
	return request({
		url: '/user/addUser',
		method: 'post',
		data
	});
}

//登录
export function login(data) {
	return request({
		url: '/user/login',
		method: 'GET',
		params: data
	});
}
// 单个用户信息
export function getUser(data) {
	return request({
		url: '/user/byId',
		method: 'GET',
		params: data
	});
}
//查询用户
export function userList(data) {
	return request({
		url: '/user/list',
		method: 'GET',
		params: data
	});
}
//修改用户
export function editUser(data) {
	return request({
		url: '/user/editUser',
		method: 'GET',
		params: data
	});
}

//删除用户
export function deleteUser(data) {
	return request({
		url: '/user/delete',
		method: 'GET',
		params: data
	});
}

// 添加项目 FormData
export function addManager(data) {
	return request({
		url: '/manager/addManager',
		method: 'POST',
		data
	});
}
// 查询项目
export function allManager(data) {
	return request({
		url: 'manager/all_manager',
		method: 'GET',
		params: data
	});
}
//修改项目
export function updateManager(data) {
	return request({
		url: '/manager/update_manager',
		method: 'PUT',
		data
	});
}
//修改图片上传 FormData
export function fileUpload(data) {
	return request({
		url: '/file',
		method: 'POST',
		data
	});
}

//删除项目
export function deleteManager(data) {
	return request({
		url: '/manager/delete',
		method: 'POST',
		data
	});
}
//Zip下载
export function zip(data) {
	return request({
		url: '/manager/getManagerFileForZip',
		method: 'get',
		params: data,
		responseType: 'blob',
	});
}

