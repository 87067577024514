<template>
  <div>
    <div class="container">
      <p class="title">欢迎使用</p>
      <p class="small-title">请使用已注册手机号登录</p>
      <div class="submit">
        <van-field
                   v-model="phone"
                   left-icon="user-circle-o"
                   placeholder="请输入手机号" />
        <van-field
                   type="password"
                   v-model="passWord"
                   left-icon="closed-eye"
                   placeholder="请输入密码" />
      </div>

      <van-button @click="subFn" class="sub" block round type="info" color="#ec6362">登录</van-button>
      <div class="bottom">
        <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备2023017816号-1</a>
      </div>
    </div>
  </div>
</template>

<script>
import { login } from '../api/index.js';
import { Notify } from 'vant';
export default {
 data() {
  return {
   phone: '',
   passWord: ''
  };
 },
 methods: {
  subFn() {
   if (this.userName == '') {
    this.$toast('请填写用户名');
    return;
   } else if (this.passWord == '') {
    this.$toast('请填写密码');
    return;
   }

   login({
    phone: this.phone,
    passwd: this.passWord
   })
    .then((res) => {
     console.log('resLogin', res);
     console.log('状态：', localStorage.getItem('record'));
     if (res.code == 200) {
      Notify({ type: 'success', message: '登录成功！', duration: 1500 });
      localStorage.setItem('phone', this.phone);
      localStorage.setItem('passwd', this.passWord);
      localStorage.setItem('record', 'true');
      localStorage.setItem('userMessage', JSON.stringify(res.data));
      this.$router.replace({ name: 'Company', params: {} });
     } else {
      this.$toast(res.message);
     }
    })
    .catch(() => {
     this.$toast('登录失败');
    });
  }
 }
};
</script>

<style lang="scss" scoped>
.container {
 max-width: 375px;
 margin: 0 auto;
 padding: 30px 30px 0;
 background-color: #f4f4f4;
text-align: center;
 height: 100vh;
}
.bottom{
  position: absolute;
  bottom: 30px;
  font-size: 12px;
  a{
  color: #ccc;
  margin-left: 70px;


  }
}
.goRegister {
 float: right;
 margin: 10px 10px;
 font-size: 14px;
}
.goRegister span {
 color: #398a86;
}
.sub {
 margin-top: 80px;
 text-align: center;
 line-height: 40px;
 border-radius: 20px;
 font-size: 18px;
 height: 40px;
 color: #fff;
 background-color: #ec6362;
}
.submit {
 display: flex;
 flex-direction: column;
 margin-top: 30px;
}
.van-cell {
 background-color: rgba(255, 255, 255, 0.925);
 border-radius: 8px;
 margin-top: 20px;
}
.title {
 margin-top: 60px;
 font-size: 22px;
 font-weight: 500;
}
.small-title {
 margin: 13px 0;
 font-weight: 500;
 font-size: 16px;

 color: #646566;
}
</style>