<template>
  <div class="container">
    <div class="top">
      <p class="title">项目管理平台</p>
      <p class="sm-title">请填写查询信息</p>
      <div class="search">
        <van-field v-model="proName" label="工程名称：" placeholder="请输入项目名称" />
        <van-field v-model="companyName" label="公司名称：" placeholder="请输入项目名称" />
        <van-field v-model="proFirst" label="甲方名称：" placeholder="请输入甲方名称" />

        <van-field v-model="proUser" placeholder="负责人" label="负责人：" />
        <van-field v-model="proType" placeholder="类型" label="类型：" />
         <van-field v-model="startAmount" label="最小合同金额：" type="number" placeholder="请输入最小金额" />
        <van-field v-model="endAmount" label="最大合同金额：" type="number" placeholder="请输入最大金额" />

        <van-field v-model="proStartTime" @click="show=true" label="开始时间：" readonly placeholder="请选择开始时间" />
        <van-field v-model="proEndTime" @click="showEnd=true" label="结束时间：" readonly placeholder="请选择结束时间" />

        <van-popup v-model="show" position="bottom" :style="{ height: '50%' }">
          <van-datetime-picker
                               swipe-duration=1000
                               @cancel='show=false'
                               @confirm='choiseTime'
                               :value='currentDate'
                               type="date"
                               title="请选择开始日期"
                               :min-date="minDate"
                               :max-date="maxDate" />
        </van-popup>
        <van-popup v-model="showEnd" position="bottom" :style="{ height: '50%' }">
          <van-datetime-picker
                               swipe-duration=1000

                               @cancel='showEnd=false'
                               @confirm='choiseEndTime'
                               :value='currentDate'
                               type="date"
                               title="选择结束日期"
                               :min-date="minDate"
                               :max-date="maxDate" />
        </van-popup>
      </div>
      <van-button block @click="searchList" class="search-btn" type="default">点击搜索</van-button>
      <div class="List" v-if="this.allList!=0">
        <div class="item" @click="goDownload(item)" v-for="item,index in allList" :key="index">
          <div class="item-top">
            <span>{{index+1}}. {{item.proName}}</span>
            <div class="btn-edit">
              <span class="data" v-if="showRole" @click.stop="goModify(item)">修改</span>
              <span class="data" v-if="showRole" @click.stop="del(item)">删除</span>
            </div>
          </div>
          <span class="user-name">公司名称：{{item.companyName}}</span>

          <span class="user-name">负责人：{{item.proUser}}</span>
          <span class="user-name">开工时间：{{item.proTime}}</span>

          <span class="user-name">详情：{{item.proConment}}</span>
        </div>
      </div>
      <div class="noList" v-else>
          暂无数据
      </div>

      <van-tabbar route>
        <van-tabbar-item to="/home" icon="wap-home-o">查询</van-tabbar-item>
        <van-tabbar-item to="/add" icon="search">添加</van-tabbar-item>
        <van-tabbar-item to='/user' icon="friends-o">我的</van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>

<script>
import { allManager, deleteManager } from '../api/index.js';
import { Dialog } from 'vant';

import moment from 'moment';

export default {
  name:'Home',
 data() {
  return {
   showRole: false,
   allList: [],
   show: false,
   showEnd: false,
   active: 0,
   companyName: '',
   proFirst:'',
   proName: '',
   proUser: '',
   proType: '',
   proStartTime: '',
   proEndTime: '',
   minDate: new Date(2015, 0, 1),
   maxDate: new Date(2035, 1, 1),
   currentDate: '',
   currentEndDate: '',
   startAmount: '',
    endAmount: ''
  };
 },

 created() {
  console.log('home',this.$route.query);
  this.companyName=this.$route.query.company;
  this.proStartTime=this.$route.query.startYear;
  this.proEndTime=this.$route.query.endYear
  this.searchList();  

 },
 activated () {
  
  this.searchList();  
  
 },

 mounted() {

  var role = JSON.parse(localStorage.getItem('userMessage')).role;
  console.log('role', role);
  if (role == 0 || role == 1) {
   this.showRole = true;
  }
	document.body.scrollTop = sessionStorage.getItem('scrollTop');

 },
 methods: {
  //  删除项目
  del(item) {
   var id = new FormData();
   id.append('id', item.id);
   Dialog.confirm({
    title: '是否删除项目'
   })
    .then(() => {
     deleteManager(id).then((res) => {
      console.log('删除res', res);
      if (res.code == 200) {
       this.searchList();
      }
     });
    })
    .catch(() => {});
  },
  //  查项目
  searchList(param) {
   allManager({
    companyName: this.companyName,
    proName: this.proName,
    proUser: this.proUser,
    proType: this.proType,
    proStartTime: this.proStartTime,
    proEndTime: this.proEndTime,
    proFirst:this.proFirst,
    startAmount:this.startAmount,
     endAmount:this.endAmount
    // pageNum: this.currentPage,
    // pageSize: 5
   }).then((res) => {
    if (res.code == 200) {
     this.allList = res.data;
     //  this.allPage = Math.ceil((res.data.totalPages - 1) / 5);
     console.log(' this.allPage', this.allList.length);
    }
    console.log('allManager', res);
   });
  },

  // 下载
  goDownload(item) {
    console.log('距离',document.body.scrollTop);
    sessionStorage.setItem('scrollTop',document.body.scrollTop)
   localStorage.setItem('itemDetail', JSON.stringify(item));
   this.$router.push('/download');
  },
  goModify(item) {
   console.log('item', item);
   sessionStorage.setItem('scrollTop',document.body.scrollTop)
   localStorage.setItem('itemDetail', JSON.stringify(item));
   this.$router.push({ name: 'modify' });
  },
  choiseTime(val) {
    console.log('rq',val);
   this.show = false;
   this.proStartTime = moment(val).format('YYYY-MM-DD');
   console.log('proStartTime:::',this.proStartTime);
  },
  choiseEndTime(val) {
   this.showEnd = false;
   this.proEndTime = moment(val).format('YYYY-MM-DD');
  }
 }
};
</script>

<style lang="scss" >
.container {
 padding: 0 20px;
 margin: 0 auto;
 //  min-height: 800px;
 background-color: #f4f4f4;
 padding-bottom: 70px;
}
.noList{
  display: flex;
  justify-content: center;
  font-size: 18px;
}
.top {
 padding-top: 37px;
 margin: 0 20px;
 max-width: 1000px;
 margin: 0 auto;
}
.search {
 box-shadow: #7f7f7f;
 border-radius: 10px !important;
 padding: 0 10px;
 background-color: #fff;
 box-sizing: content-box;
 max-width: 1000px;
 margin: 0 auto;
 display: flex;
 flex-direction: column;
}
.btn-edit {
 display: flex;
}
.btn-edit span:first-child {
 margin-right: 5px;
 background-color: #009688;
}
.page {
 margin: 0 auto;
 max-width: 300px;
 margin-top: 10px;
}
.van-cell__title {
 font-size: 16px !important;
}
.van-cell .van-field__control {
 font-size: 16px !important;
}
input::placeholder {
 color: #c8c9cc;
}
.search-btn {
 background-color: #3b8884;
 color: #fff;
 border-radius: 8px;
 width: 100%;
 // max-width: 100px;
 margin: 15px auto;
}
.van-cell__value .van-field__value {
 font-size: 30px !important;
}
.title {
 font-size: 26px;
}
.sm-title {
 font-size: 14px;
 margin: 20px 0 10px;
 color: #7f7f7f;
}
.List {
 background-color: #fff;
 font-size: 14px;
 // padding-bottom: 50px;
 // margin-bottom: 50px;
}
.item {
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 padding: 10px 20px;
 border-bottom: 1px solid #ececec;
}
.user-name {
 font-size: 12px;
 color: #7f7f7f;
 margin-top: 8px;
 white-space: nowrap;
 overflow: hidden;
 text-overflow: ellipsis;
}
.data {
 
 display: flex;
 justify-content: center;
 align-items: center;
 width: 42px;
 height: 25px;
 font-size: 12px;
 border-radius: 8px;
 color: #fff;
 background-color: #f44336;
}
.item-top {
 display: flex;
 align-items: center;
 justify-content: space-between;
}
.van-tabbar-item--active {
 color: #ec6362;
}
</style>