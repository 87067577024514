<template>
  <div class="container">
    <div class="main">
      <div class="userInfo">
        <img src="../assets/img/user.png" alt="">
        <div class="user-detail">
          <span>{{userInfo.name}}<van-icon @click="editSelf" class="edit" name="edit" /></span>
          <span>{{userInfo.phone}}</span>
        </div>
        <div class="addUser" @click="addUser" v-if="userInfo.role==0">
          <van-button size="small" color="#009688" class="add-btn" type="primary">添加业务员</van-button>
        </div>

      </div>

      <van-collapse v-model="activeNames" v-if="userInfo.role==0">
        <van-collapse-item icon='friends-o' title="成员" name="1">
          <div v-for="item,index in userList" :key="index">
            <div class="user-list" v-if="item.id!=id">
              <span><van-icon v-if="item.role==0" color="red" name="user-o" />{{item.name}} - {{item.phone}}</span>
              <div>
                <van-button class="edit-btn" @click="edit(item)" color="#009688" size='small'>修改</van-button>
                <van-button @click="del(item)" color="red" size='small'>删除</van-button>
                <!-- <van-switch size="20px" v-model="checked" /> -->
              </div>
            </div>
          </div>

        </van-collapse-item>

      </van-collapse>
      <!-- 注册用户 -->
      <van-dialog v-model="showAdd" @confirm='addSuc' title="注册用户" show-cancel-button>
        <van-field v-model="addName" label="用户名：" placeholder="请输入用户名" />
        <van-field v-model="addPhone" label="手机号：" placeholder="请输入手机号" />
        <van-field v-model="addPasswd" label="密码：" placeholder="请输入密码" />
        <div class="modify">修改权限：
          <van-switch size="20px" @change="changeSwitch" v-model="addChecked" />
        </div>
      </van-dialog>
      <!-- 修改弹窗 -->
      <van-dialog v-model="show" @confirm='suc' title="修改用户信息" show-cancel-button>
        <van-field v-model="editUser.name" label="用户名：" placeholder="请输入用户名" />
        <van-field v-model="editUser.phone" label="手机号：" placeholder="请输入手机号" />
        <van-field v-model="editUser.passwd" label="密码：" placeholder="请输入密码" />
        <div class="modify">修改权限：
          <van-switch size="20px" @change="changeSwitch" v-model="checked" />
        </div>
      </van-dialog>
      <!-- 修改自己信息 -->
      <van-dialog v-model="showSelf" @confirm='selfSuc' title="修改用户信息" show-cancel-button>
        <van-field v-model="editUser.name" label="用户名：" placeholder="请输入用户名" />
        <van-field v-model="editUser.phone" label="手机号：" :disabled='changePhone' placeholder="请输入手机号" />
        <van-field v-model="editUser.passwd" label="密码：" placeholder="请输入密码" />
      </van-dialog>
    </div>
    <div class="out-btn">
      <van-button @click="outUser" type="danger">退出登录</van-button>
    </div>
    <van-tabbar route>
      <van-tabbar-item to="/home" icon="wap-home-o">查询</van-tabbar-item>
      <van-tabbar-item to="/add" icon="search">添加</van-tabbar-item>
      <van-tabbar-item to='/user' icon="friends-o">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { userList, register, getUser, deleteUser, editUser } from '../api/index.js';
import { Dialog } from 'vant';
import { Notify } from 'vant';

export default {
 components: {
  [Dialog.Component.name]: Dialog.Component
 },
 data() {
  return {
   changePhone: false,
   addName: '',
   addPhone: '',
   addPasswd: '',
   editUser: {},
   role: '',
   addRole: 2,
   show: false,
   showAdd: false,
   showSelf: false,
   id: '',
   editId: '',
   userInfo: {},
   checked: true,
   addChecked: false,
   activeNames: ['1'],
   name: '王先生',
   userList: [],
   phoneNumberTest: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
  };
 },
 created() {
  //  当前人id
  this.id = JSON.parse(localStorage.getItem('userMessage')).id;
  this.getUserList();
  this.getUserSingle();
	

 },
 methods: {
  outUser() {
   Dialog.confirm({
    title: '是否退出登录'
    // message: '弹窗内容'
   })
    .then(() => {
     localStorage.setItem('record', 'false');
     this.$router.replace('login');
    })
    .catch(() => {});
  },
  addSuc() {
   if (!this.phoneNumberTest.test(this.addPhone)) {
    this.$toast({ message: '请输入正确手机号', duration: 1500 });
    return;
   }
   this.addChecked ? (this.addRole = 1) : (this.addRole = 2);
   register({
    name: this.addName,
    phone: this.addPhone,
    passwd: this.addPasswd,
    role: this.addRole
   }).then((res) => {
    if (res.data == 1) {
     this.getUserList();
     Notify({ type: 'success', message: '注册成功！', duration: 1000 });
     this.addName = '';
     this.addPhone = '';
     this.addPasswd = '';
     this.addRole = 2;
    } else {
     Notify({ type: 'danger', message: '该用户已注册' });
    }
   });
  },
  addUser() {
   this.showAdd = true;

   //  this.$router.push('register');
  },
  addSwitch(val) {
   this.addChecked = val;
  },
  changeSwitch(val) {
   console.log('changeSwitch', val);
   this.checked = val;
  },
  // 注册用户

  // 当前人信息
  getUserSingle() {
   getUser({
    id: this.id
   }).then((res) => {
    this.userInfo = res.data;
    if (this.userInfo.role == 0) {
     this.changePhone = false;
    } else {
     this.changePhone = true;
    }
    console.log('resUserSingle', res);
   });
  },
  // 超级管理员-所有用户
  getUserList() {
   userList({}).then((res) => {
    this.userList = res.data;
    document.body.scrollTop = 0;
    console.log('resUserList', res);
   });
  },
  // 修改自己信息
  selfSuc() {
   editUser({
    id: this.editUser.id,
    name: this.editUser.name,
    phone: this.editUser.phone,
    passwd: this.editUser.passwd,
    role: this.editUser.role
   }).then((res) => {
    if (res.code == 200) {
     localStorage.setItem('phone', this.editUser.phone);
     localStorage.setItem('passwd', this.editUser.passwd);
     this.getUserSingle();
    }
    console.log('resUserSingle', res);
   });
  },
  // 修改业务员信息
  suc() {
   this.checked ? (this.role = 1) : (this.role = 2);
   editUser({
    id: this.editUser.id,
    name: this.editUser.name,
    phone: this.editUser.phone,
    passwd: this.editUser.passwd,
    role: this.role
   }).then((res) => {
    if (res.code == 200) {
     this.getUserList();
    }
   });
  },
  // 修改自己
  editSelf(value) {
   this.showSelf = true;
   this.editUser = { ...this.userInfo };
  },
  // 修改用户
  edit(item) {
   this.editUser = { ...item };
   if (item.role == 2) {
    this.checked = false;
   } else {
    this.checked = true;
   }
   this.show = true;
  },
  // 删除用户
  del(item) {
   console.log('item:', item);
   Dialog.confirm({
    title: '是否删除用户'
    // message: '弹窗内容'
   })
    .then(() => {
     deleteUser({
      id: item.id
     }).then((res) => {
      // this.userList = res.data.records;
      this.getUserList();
      console.log('deletUser:', res);
     });
     // on confirm
    })
    .catch(() => {
     // on cancel
    });
  }
 }
};
</script>

<style lang="scss" scoped>
.container {
 position: relative;
 padding: 20px 20px 50px;
 margin: 0 auto;
 //  min-height: 700px;
 height: 100vh;
 overflow: auto;
 background-color: #f4f4f4;
}

.edit-btn {
 margin-right: 5px;
}
.out-btn {
 margin: 0 auto;
 display: flex;
 justify-content: center;
 max-width: 990px;
 margin-top: 20px;
}
.out-btn button {
 margin: 0 auto;
 width: 100%;
 color: #fff;
 border-radius: 8px;
}
.main {
 background-color: #fff;

 max-width: 1000px;
 //  min-height: 700px;
 margin: 0 auto;
 //  margin-bottom: 100px;
 border-radius: 8px;
}
.popup {
 padding: 10px;
 margin: 0 auto;
 max-width: 1000px;
 min-height: 700px;
 font-size: 24px;
}
.addUser {
 display: flex;
 align-items: center;
}
.add-btn {
 //  height: 30px;
 border-radius: 8px;
}
.modify {
 font-size: 16px;
 color: #646566;
 padding: 10px 16px;
 display: flex;
 justify-content: flex-start;
 align-items: center;
}
.van-switch {
 margin-left: 30px;
}
.van-button--small {
 height: 26px;
 border-radius: 8px;
}
.user-list {
 display: flex;
 justify-content: space-between;
 align-items: center;
 height: 50px;
 font-size: 15px;
 border-bottom: 1px solid #ececec;
}
.userInfo {
 padding: 10px;
 display: flex;
 justify-content: space-around;
 font-size: 16px;
 color: rgba(0, 0, 0, 0.918);
}
.userInfo img {
 width: 60px;
 height: 60px;
}
.user-detail {
 padding: 10px 5px;
 margin-left: 10px;
 display: flex;
 flex: 2;
 flex-direction: column;
 justify-content: space-between;
}
.edit {
 margin-left: 10px;
}
</style>