<template>
  <div class="container">
    <div class="main">     
      <van-field v-model="itemDetail.companyName" readonly label="公司名称：" placeholder="请输入公司名称" />
      <van-field v-model="itemDetail.proType" readonly placeholder="类型" label="类型：" />
      <van-field v-model="itemDetail.proUser" readonly placeholder="请输入负责人" label="负责人：" />
      <van-field v-model="itemDetail.proPhone" readonly placeholder="请输入负责人电话" label="负责人电话：" />
      <van-field v-model="itemDetail.projectManager" placeholder="请输入项目经理名称" label="项目经理：" />

      <van-field v-model="itemDetail.proFirst" readonly placeholder="请输入甲方名称" label="甲方名称：" />
      <van-field v-model="itemDetail.invoicingInfo" readonly placeholder="请输入开票信息" label="开票信息：" />
      <van-field v-model="itemDetail.proName" readonly placeholder="请输入工程名称" label="工程名称：" />
      <van-field v-model="itemDetail.proAddr" readonly placeholder="请输入工程地址" label="工程地址：" />
      <van-field v-model="itemDetail.proAmt" readonly placeholder="请输入合同金额" label="合同金额：" />

      <van-field v-model="itemDetail.proTime" readonly  label="开工日期："  placeholder="请选择提交日期" />      
      <van-field v-model="itemDetail.completeTime" readonly  label="竣工日期："  placeholder="请选择竣工日期" />      

      <van-field
                 readonly
                 v-model="itemDetail.proConment"
                 rows="2"
                 autosize
                 label="详情"
                 type="textarea"
                 maxlength="50"
                 placeholder="请输入留言"
                 show-word-limit />

      

      <div class="img-title">合同资料：</div>
      <div class="img-container">
        <div class="imgFn" v-for="item,index in contractShow" :key="index">
          <img @click="show(item)" :src="item" alt="">
          <van-button @click="donw(item,index)" type="primary">下载</van-button>
        </div>
      </div>
      <div class="img-title">中标通知书资料：</div>
      <div class="img-container">
        <div class="imgFn" v-for="item,index in successfulShow" :key="index">
          <img @click="show(item)" :src="item" alt="">
          <van-button @click="donw(item,index)" type="primary">下载</van-button>
        </div>
      </div>
      <div class="img-title">验收单资料：</div>
      <div class="img-container">
        <div class="imgFn" v-for="item,index in acceptanceShow" :key="index">
          <img @click="show(item)" :src="item" alt="">
          <van-button @click="donw(item,index)" type="primary">下载</van-button>
        </div>
      </div>
      <div class="img-title">收款单资料：</div>
      <div class="img-container">
        <div class="imgFn" v-for="item,index in receiptsShow" :key="index">
          <img @click="show(item)" :src="item" alt="">
          <van-button @click="donw(item,index)" type="primary">下载</van-button>
        </div>
      </div>
      <div class="img-title">竣工资料：</div>
      <div class="img-container">
        <div class="imgFn" v-for="item,index in informationShow" :key="index">
          <img @click="show(item)" :src="item" alt="">
          <van-button @click="donw(item,index)" type="primary">下载</van-button>
        </div>
      </div>
      <div class="img-title">发票资料：</div>
      <div class="img-container">
        <div class="imgFn" v-for="item,index in invoiceShow" :key="index">
          <img @click="show(item)" :src="item" alt="">
          <van-button @click="donw(item,index)" type="primary">下载</van-button>
        </div>
      </div>
      <div class="img-title">公示资料：</div>
      <div class="img-container">
        <div class="imgFn" v-for="item,index in screenshotShow" :key="index">
          <img @click="show(item)" :src="item" alt="">
          <van-button @click="donw(item,index)" type="primary">下载</van-button>
        </div>
      </div>
      <div class="img-title">预算资料：</div>
      <div class="img-container">
        <div class="imgFn" v-for="item,index in budgetShow" :key="index">
          <img @click="show(item)" :src="item" alt="">
          <van-button @click="donw(item,index)" type="primary">下载</van-button>
        </div>
      </div>
      <div class="img-title">其他资料：</div>
      <div class="img-container">
        <div class="imgFn" v-for="item,index in otherShow" :key="index">
          <img @click="show(item)" :src="item" alt="">
          <van-button @click="donw(item,index)" type="primary">下载</van-button>
        </div>
      </div>
    </div>
    <div class="zip">
          <van-button round @click="zipDown" type="primary">资料Zip下载</van-button>

    </div>

    <van-tabbar route>
      <van-tabbar-item to="/home" icon="wap-home-o">查询</van-tabbar-item>
      <van-tabbar-item to="/add" icon="search">添加</van-tabbar-item>
      <van-tabbar-item to='/user' icon="friends-o">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { addManager,zip } from '../api/index.js';
import { ImagePreview } from 'vant';
import FileSaver from 'file-saver';
import moment from 'moment';

export default {
 name: 'download',
 data() {
  return {
   companyName: '',
   user: '',
   type: '',
   message: '',
   showEnd: false,
   currentDate: '2023-6-1',
   minDate: new Date(2020, 0, 1),
   maxDate: new Date(2030, 1, 1),
   contractShow: [], //合同
   successfulShow: [], //中标通知书
   acceptanceShow: [], //验收单
   receiptsShow: [], //收款单
   informationShow: [], //竣工资料
   invoiceShow: [], //发票资料
   screenshotShow: [], //公示资料
   budgetShow: [], //预算资料
   otherShow: [], //其他
   imgList: ['http://43.143.227.24/T.png'],
   imgList1: [
    'http://43.143.227.24/T.png',
    'http://43.143.227.24/test.jpg',
    'http://43.143.227.24/test.jpg',
    'http://43.143.227.24/test.jpg',
    'http://43.143.227.24/test.jpg',
    'http://43.143.227.24/test.jpg'
   ],
   itemDetail: {},
   file: '',
   downList: [],  
  };
 },
 activated () {

   this.itemDetail={};
    this.contractShow= [], //合同
   this.successfulShow= [], //中标通知书
   this.acceptanceShow= [], //验收单
   this.receiptsShow= [], //收款单
   this.informationShow= [], //竣工资料
   this.invoiceShow= [], //发票资料
   this.screenshotShow= [], //公示资料
   this.budgetShow= [], //预算资料
   this.otherShow= [],
   console.log('activated');
   this.itemDetail = JSON.parse(localStorage.getItem('itemDetail'));
  var imgList = this.itemDetail.pngAll[0];
  console.log('down', imgList);
  for (const key in imgList) {
   if (key == 'contract') {
    imgList[key].split(',').forEach((element) => {
     if (element) {
      this.contractShow.push(element);
     }
    });
   }
   if (key == 'successful') {
    imgList[key].split(',').forEach((element) => {
     if (element) {
      this.successfulShow.push(element);
     }
    });
   }
   if (key == 'acceptance') {
    imgList[key].split(',').forEach((element) => {
     if (element) {
      this.acceptanceShow.push(element);
     }
    });
   }
   if (key == 'receipts') {
    imgList[key].split(',').forEach((element) => {
     if (element) {
      this.receiptsShow.push(element);
     }
    });
   }
   if (key == 'information') {
    imgList[key].split(',').forEach((element) => {
     if (element) {
      this.informationShow.push(element);
     }
    });
   }
   if (key == 'invoice') {
    imgList[key].split(',').forEach((element) => {
     if (element) {
      this.invoiceShow.push(element);
     }
    });
   }
   if (key == 'screenshot') {
    imgList[key].split(',').forEach((element) => {
     if (element) {
      this.screenshotShow.push(element);
     }
    });
   }
   if (key == 'budget') {
    imgList[key].split(',').forEach((element) => {
     if (element) {
      this.budgetShow.push(element);
     }
    });
   }
   if (key == 'other') {
    imgList[key].split(',').forEach((element) => {
     if (element) {
      this.otherShow.push(element);
     }
    });
   }
  }
 },
 created(){

 },
 mounted() {
  // 新增activited，mounted->activated

  
 },
 methods: {
  show(item) {
   ImagePreview({images:[item],closeable: true});
  },
  async donw(item, index) {
    console.log('item',item);
   //  var alink = document.createElement('a');
   //  alink.href = item;
   //  alink.download = index; //图片名
   //  alink.click();
   // 1
   //  var image = new Image();
   //  // 解决跨域 Canvas 污染问题
   //  image.setAttribute('crossOrigin', 'anonymous');
   //  image.onload = function () {
   //   var canvas = document.createElement('canvas');
   //   canvas.width = image.width;
   //   canvas.height = image.height;
   //   var context = canvas.getContext('2d');
   //   context.drawImage(image, 0, 0, image.width, image.height);
   //   var url = canvas.toDataURL('image/png'); //得到图片的base64编码数据
   //   var a = document.createElement('a'); // 生成一个a元素
   //   var event = new MouseEvent('click'); // 创建一个单击事件
   //   a.download = name || 'photo'; // 设置图片名称
   //   a.href = url; // 将生成的URL设置为a.href属性
   //   a.dispatchEvent(event); // 触发a的单击事件
   //  };
   //  image.src = item;
   //  2
   fetch(item, { method: 'get' }).then((res) =>
    res.blob().then((blob) => {
     var reader = new FileReader();
     reader.readAsDataURL(blob); // 转换为base64，可以直接放入a标签href
     reader.onload = function (e) {
      var down = document.createElement('a'); // 转换完成，创建一个a标签用于下载
      down.download = index;
      down.href = String(e.target.result);
      // document.appendChild(down)
      down.click();
      down.remove();
     };
    })
   );
  },
zipDown(){
  console.log('itemDetail',this.itemDetail);  
zip({id:this.itemDetail.id}).then(res=>{  
  this.fileHandle(res,`${this.itemDetail.proName}.zip`)
})
},
fileHandle(data, fileName) {
      let blob = new Blob([data], { type: 'application/zip' })//此处必须添加)
      let url = window.URL.createObjectURL(blob)
      const link = document.createElement('a') // 创建a标签
      link.href = url
      link.download = fileName // 重命名文件
      link.click()
      URL.revokeObjectURL(url) // 释放内存
    }
,


downzip(file,name){  

let url = window.URL.createObjectURL(new Blob([file]),{ type: 'application/zip' });

let link = document.createElement('a');

link.style.display = 'none';

link.href = url;

link.setAttribute('download', name);

document.body.appendChild(link);

link.click();

document.body.removeChild(link); // 下载完成移除元素

window.URL.revokeObjectURL(url); // 释放掉blob对象


},
  choiseEndTime(val) {
   this.showEnd = false;
   this.currentDate = moment(val).format('YYYY-MM-DD');
  }
 }
};
</script>

<style lang="scss" scoped>
.zip{
  display: flex;
  justify-content: center;
  margin-top: 20px;
  border-radius: 5px;
}
.container {
 padding: 10px 20px 120px;
 margin: 0 auto;
 min-height: 1000px;
 background-color: #f4f4f4;
}
.btn {
 width: 50px;
 height: 30px;
 background-color: #36d;
 margin: 50px;
}
.img-container {
 padding-left: 16px;
 display: flex;

 flex-wrap: wrap;
}
.imgFn {
 display: flex;
 flex-direction: column;
 margin-right: 10px;
 margin-bottom: 10px;
 align-items: center;
 width: 65px;
 font-size: 20px;
}
.imgFn button {
 width: 50px;
 height: 20px;
 padding: 0;
 margin-top: 5px;
}
.imgFn img {
 width: 65px;
 height: 65px;
}
.main {
 background-color: #fff;
 margin: 0 20px;
 max-width: 1000px;
 margin: 0 auto;
}
.title {
 font-size: 22px;
 padding: 15px 16px;
}
.img-title {
 font-size: 16px;
 color: #646566;
 padding: 10px 16px;
}
.van-uploader {
 margin: 10px 16px;
}
</style>