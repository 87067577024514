<template>
  <div class="container">
   
    <div class="main">
       <div class="title">新增项目</div>
      <van-field v-model="company_name" @click="showCompany=true" readonly label="公司名称：" placeholder="请输入公司名称" />
      <van-field v-model="pro_type" placeholder="类型" label="类型：" />
      <van-field v-model="pro_user" placeholder="请输入负责人" label="负责人：" />
      <van-field v-model="pro_phone"  type="number" placeholder="请输入负责人联系方式" label="负责人电话：" />

      <van-field v-model="pro_first" placeholder="请输入甲方名称" label="甲方名称：" />
      <van-field v-model="projectManager" placeholder="请输入项目经理名称" label="项目经理：" />

      
      <van-field v-model="Invoicing_info" placeholder="请输入开票信息" label="开票信息：" />
      <van-field v-model="pro_name" placeholder="请输入工程名称" label="工程名称：" />
      <van-field
                 v-model="pro_addr"
                 rows="2"
                 autosize
                 label="工程地址："
                 type="textarea"
                 maxlength="50"
                 placeholder="请输入工程地址"
                 show-word-limit />
      <!-- <van-field v-model="pro_addr" placeholder="请输入工程地址" label="工程地址：" /> -->
      <van-field v-model="pro_amt"  type="number" placeholder="请输入合同金额" label="合同金额：" />

      <van-field v-model="pro_time" readonly @click="showEnd=true" label="开工日期：" placeholder="请选择提交日期" />
      <van-field v-model="completeTime" readonly @click="showCompleteTime=true" label="竣工日期：" placeholder="请选择竣工日期" />


      <van-popup v-model="showEnd" position="bottom" :style="{ height: '50%' }">
        <van-datetime-picker
                             swipe-duration=1000
                             @cancel='showEnd=false'
                             @confirm='choiseEndTime'
                             :value='currentDate'
                             type="date"
                             title="选择结束日期"
                             :min-date="minDate"
                             :max-date="maxDate" />
      </van-popup>
       <van-popup v-model="showCompleteTime" position="bottom" :style="{ height: '50%' }">
        <van-datetime-picker
                             swipe-duration=1000
                             @cancel='showCompleteTime=false'
                             @confirm='choiseCompleteTime'
                             :value='currentDate'
                             type="date"
                             title="选择结束日期"
                             :min-date="minDate"
                             :max-date="maxDate" />
      </van-popup>

      <van-field
                 v-model="pro_conment"
                 rows="2"
                 autosize
                 label="详情"
                 type="textarea"
                 maxlength="50"
                 placeholder="请输入留言"
                 show-word-limit />
      <div class="img-title">合同资料：</div>

      <van-uploader name='contractFiles' :preview-options="option" v-model="contractFiles" multiple
                    :before-delete='delImg' :after-read="imgFn">
      </van-uploader>
      <div class="img-title">中标通知书资料：</div>
      <van-uploader name="successfulFiles" :preview-options="option" v-model="successfulFiles" :after-read="imgFn" />
      <div class="img-title">验收单资料：</div>
      <van-uploader name="acceptanceFiles" :preview-options="option" v-model="acceptanceFiles" :after-read="imgFn" />
      <div class="img-title">收款单资料：</div>
      <van-uploader name="receiptsFiles" :preview-options="option" v-model="receiptsFiles" :after-read="imgFn" />
      <div class="img-title">竣工资料：</div>
      <van-uploader name="informationFiles" :preview-options="option" v-model="informationFiles" :after-read="imgFn" />
      <div class="img-title">发票资料：</div>
      <van-uploader name="invoiceFiles" :preview-options="option" v-model="invoiceFiles" :after-read="imgFn" />
      <div class="img-title">公示资料：</div>
      <van-uploader name="screenshotFiles" :preview-options="option" v-model="screenshotFiles" :after-read="imgFn" />
      <div class="img-title">预算资料：</div>
      <van-uploader name="budgetFiles" :preview-options="option" v-model="budgetFiles" :after-read="imgFn" />
      <div class="img-title">其他资料：</div>
      <van-uploader name="otherFiles" :preview-options="option" v-model="otherFiles" :after-read="imgFn" />
    </div>
    <div class="btn-container">
      <button class="btn" @click="submit">提交项目</button>
    </div>
    <!-- 选择公司 -->
    <van-popup v-model="showCompany" position="bottom" :style="{ height: '50%' }">
      <van-picker
                  v-model="showCompany"
                  title="选择公司"
                  show-toolbar
                  :columns="columns"
                  @confirm="selectCompany" />
    </van-popup>
    <van-tabbar route>
      <van-tabbar-item to="/home" icon="wap-home-o">查询</van-tabbar-item>
      <van-tabbar-item to="/add" icon="search">添加</van-tabbar-item>
      <van-tabbar-item to='/user' icon="friends-o">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { addManager } from '../api/index.js';
import Compressor from 'compressorjs';
import moment from 'moment';
import { Notify, Toast } from 'vant';

export default {
 name: 'add',
 data() {
  return {
    option:{closeable: true},
   showCompany: false,
   showCompleteTime:false,
   columns: ['北京一力建设', '中际三立', '北京华施建设', '爱巢家', '辉跃建设'],
   company_name: '', //公司名称1
   pro_name: '', //项目名称
   pro_type: '', //项目类型1
   pro_user: '', //负责人1
   projectManager:'',//项目经理
   pro_phone: '', //负责人电话1
   pro_first: '', //甲方名称
   Invoicing_info: '', //开票信息
   pro_conment: '', //项目描述
   pro_addr: '', //工程地址
   pro_amt: '', //工程金额
   pro_time: '', //工程时间
   completeTime:'',//竣工时间
   contractFiles: [], //合同
   successfulFiles: [], //中标通知书
   acceptanceFiles: [], //验收单
   receiptsFiles: [], //收款单
   informationFiles: [], //竣工资料
   invoiceFiles: [], //发票资料
   screenshotFiles: [], //公示资料
   budgetFiles: [], //预算资料
   otherFiles: [], //其他资料
   showEnd: false,
   currentDate: '',
   minDate: new Date(2015, 0, 1),
   maxDate: new Date(2035, 1, 1),
   downList: [],
   obj: new FormData(),
   moneyTest:/^[0-9]+\.{0,1}[0-9]{0,2}$/
  };
 },
 methods: {
  
  close(url) {
   console.log('关闭', url);
   this.donw(url);
  },
  delImg(val) {
   console.log(val);
  },
  donw(item) {
   fetch(item.url, { method: 'get' }).then((res) =>
    res.blob().then((blob) => {
     var reader = new FileReader();
     reader.readAsDataURL(blob); // 转换为base64，可以直接放入a标签href
     reader.onload = function (e) {
      var down = document.createElement('a'); // 转换完成，创建一个a标签用于下载
      down.download = item.index;
      down.href = String(e.target.result);
      // document.appendChild(down)
      down.click();
      down.remove();
     };
    })
   );
  },
  submit() {
   Toast.loading({
    message: '加载中...',
    forbidClick: true
   });
   this.obj.append('companyName', this.company_name);
   this.obj.append('proName', this.pro_name);
   this.obj.append('proType', this.pro_type);
   this.obj.append('proUser', this.pro_user);
   this.obj.append('proPhone', this.pro_phone);
   this.obj.append('proFirst', this.pro_first);
   this.obj.append('InvoicingInfo', this.Invoicing_info);
   this.obj.append('proConment', this.pro_conment);
   this.obj.append('proAddr', this.pro_addr);
   this.obj.append('proAmt', this.pro_amt);
   this.obj.append('proTime', this.pro_time);
   this.obj.append('completeTime', this.completeTime);
   this.obj.append('projectManager', this.projectManager);

   

   if(this.company_name==''){
     Toast('公司名称不能为空');
   }
   addManager(this.obj).then((res) => {
    if (res.code == 200) {
     this.obj = new FormData();
     Notify({ type: 'success', message: '添加成功', duration: 1500 });
     this.$router.push('home');
    }
    console.log('resAdd', res);
   });
  },
  choiseEndTime(val) {
   this.showEnd = false;
   this.pro_time = moment(val).format('YYYY-MM-DD');
  },
  choiseCompleteTime(val) {
   this.showCompleteTime = false;
   this.completeTime = moment(val).format('YYYY-MM-DD');
  },
  async imgFn(file, detail) {
   console.log('fileFirst:', file, detail);
   file.status = 'uploading';
   file.message = '上传中';
   for (let index = 0; index < file.length; index++) {
    if (file.length > 1) {
     file[index].status = 'uploading';
     file[index].message = '上传中';
    }
   }
   let files = [];
   if (!file.length) {
    files.push(file);
   } else {
    files = [...file];
   }
   console.log('file', file);
   console.log('files', file);
   if (files.length) {
    for (let index = 0; index < files.length; index++) {
     let compress = await this.compressFile(files[index].file); //上传压缩
     files[index].status = 'done';
     files[index].message = '成功';
     console.log('files', files[index].file.name);
     this.obj.append(detail.name, compress, files[index].file.name);
    }
   } else {
    files.status = 'done';
    files.message = '成功';
   }
   console.log('obj:', this.obj.getAll('contractFiles'));
  },
  compressFile(file) {
   return new Promise((resolve, reject) => {
    new Compressor(file, {
     //  maxWidth: 2000,
     //  maxHeight: 2000,
     qulity: 0.5,
     convertTypes: ['images/jpg', 'images/png', 'images/jpeg'],
     success: resolve
    });
   });
  },
  selectCompany(value) {
   this.company_name = value;
   this.showCompany = false;
  }
 }
};
</script>

<style lang="scss" scoped>
.container {
 padding: 10px 20px;
 margin: 0 auto;
 min-height: 1000px;

 background-color: #f4f4f4;
}

.btn-container {
 display: flex;
 justify-content: center;
 align-items: center;
 margin-top: 20px;
 margin-bottom: 70px;
}
.btn {
 padding: 8px 10px;
 color: #fff;
 background-color: #36d;
 font-size: 16px;
 border-radius: 12px;
}
.main {
 background-color: #fff;
 margin: 0 20px;
 max-width: 1000px;
 margin: 0 auto;
}
.title {
 font-size: 22px;
 margin: 10px 0;
 padding: 10px;
}
.img-title {
 font-size: 16px;
 color: #646566;
 padding: 10px 16px;
}
.van-uploader {
 margin: 10px 16px;
}
</style>