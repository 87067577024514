import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Login from '@/views/Login';
import Register from '@/views/Register';
import Add from '@/views/Add';
import modify from '@/views/modify';
import download from '@/views/download';
import user from '@/views/user';
import company from '@/views/SelectCompany';
import SelectYear from '@/views/SelectYear'

import { login } from '../api/index.js';
import { Notify } from 'vant';
import { format } from 'core-js/fn/date';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'login',
		component: Login
	},
	{
		path: '/login',
		name: 'Login',
		component: Login
	},
	{
		path: '/register',
		name: 'Register',
		component: Register
	},
	{
		path: '/home',
		name: 'Home',
		meta: {
			keepAlive: true
		},
		component: Home
	},
	{
		path: '/add',
		name: 'Add',
		meta: {
			keepAlive: false
		},
		component: Add
	},
	{
		path: '/modify',
		name: 'modify',
		meta: {
			keepAlive: true
		},
		component: modify
	},
	{
		path: '/download',
		name: 'download',
		meta: {
			keepAlive: true
		},
		component: download
	},
	{
		path: '/user',
		name: 'User',
		meta: {
			keepAlive: true
		},
		component: user
	},
	{
		path: '/company',
		name: 'Company',
		meta: {
			keepAlive: false
		},
		component: company
	}	,
	{
		path: '/selectYear',
		name: 'selectYear',
		meta: {
			keepAlive: false
		},
		component: SelectYear
	}	
];

const router = new VueRouter({
	// mode: 'history',
	// base: process.env.BASE_URL,
	routes
});
router.beforeEach((to, from, next) => {
	// document.body.scrollTop = 0;
	var phone = localStorage.getItem('phone');
	var passwd = localStorage.getItem('passwd');
	console.log('用户，密码：',from);

	login({
		phone: phone,
		passwd: passwd
	})
		.then((res) => {
			console.log('res', res);
			if(to.name=='Home'){
				document.body.scrollTop = sessionStorage.getItem('scrollTop');
			}else{
				document.body.scrollTop = 0
			}

			if (res.code == 200) {
				localStorage.setItem('userMessage', JSON.stringify(res.data));
			} else {
				console.log('设置状态');
				localStorage.setItem('record', 'false');
				Notify({ type: 'danger', message: '身份过期请登录', duration: 1500 });				
				if(form.name=='Register'){
				next('rfegister');	
				console.log('ress');
				return;
				}else{
					next('login');
				return;
				}
			}
		})
		.catch((res) => {
			localStorage.setItem('record', 'false');
			Notify({ type: 'danger', message: '用户失效', duration: 1500 });
			// next('login');
			if(form.name=='Register'){
				next('rfegister');	
				console.log('ress');
				return;
				}else{
					next('login');
				return;
				}
		});
	var record = localStorage.getItem('record');
	if (record == 'true') {
		next();
	} else {
		if (
			to.path.indexOf('/add') != -1 ||
			to.path.indexOf('/home') != -1 ||
			to.path.indexOf('/modify') != -1 ||
			to.path.indexOf('/download') != -1 ||
			to.path.indexOf('/user') != -1
		) {
			Notify({ type: 'danger', message: '身份过期请登录', duration: 1500 });			
			next('login');
		} else {
			next();
		}
	}
	console.log('123',sessionStorage.getItem('scrollTop'));
	document.body.scrollTop = sessionStorage.getItem('scrollTop');

});

export default router;
