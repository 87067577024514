<template>
    <div class="container">
        
        <div class="top">
            <span class="title">请选择公司</span>
            <div class="choise" >                    
                <van-button color="#3b8885" @click="goYear('北京一力建设')" text="北京一力建设" type="default"></van-button>
                <van-button color="#3b8885"  @click="goYear('中际三立')" text="中际三立" type="default"></van-button>
                <van-button color="#3b8885"  @click="goYear('北京华施建设')" text="北京华施建设" type="default"></van-button>
                <van-button color="#3b8885" @click="goYear('爱巢家')" text="爱巢家" type="default"></van-button>
                <van-button color="#3b8885" @click="goYear('辉跃建设')"  text="辉跃建设" type="default"></van-button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'company',
        data(){
            return {
                mes: ''
            };
        },
        methods: {
            goYear(event){
                console.log('item',event);
                 this.$router.push({name:'selectYear',query:{company:event}});

            }
    }
    }
</script>

<style lang="scss" scoped>
.container {
 padding: 0px 20px;
 margin: 0 auto;
 min-height: 800px;
 background-color: #f4f4f4;
 padding-bottom: 70px;
}

.choise{
    background-color: #fff;
    min-height: 500px;
    // padding-bottom: 30px;
    border-radius: 10px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    
.van-button--normal {
    width: 250px;
    margin-top: 30px;
    border-radius: 8px;
    font-size: 16px;
}
}
.title {
 font-size: 24px;
color: #000000c1;
}
</style>